import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const NavbarContainer: FC<Props> = ({ children, className }) => {
  return (
    <nav className={`z-50 w-full ${className} sticky top-0 border-b border-common-stroke bg-white`}>
      {children}
    </nav>
  );
};

export default NavbarContainer;
