import useRequest from '@services/api';
import MainLayout from './MainLayout';
import SimpleLayout from './SimpleLayout';

const HybridLayout = ({ children }) => {
  let { data, isLoading } = useRequest.user.get(); //TODO: Remove me and load only after login
  const userData = !isLoading && data;

  if (userData) {
    return <MainLayout>{children}</MainLayout>;
  } else {
    return <SimpleLayout>{children}</SimpleLayout>;
  }
};

export default HybridLayout;
