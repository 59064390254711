import AppLogo from '@assets/svg/AppLogo';
import useUser from '@hooks/use-user';
import { FC } from 'react';
import NavbarContainer from './navbar-container';

interface Props {
  isSignIn?: boolean;
}

const Navbar: FC<Props> = () => {
  const { data } = useUser();
  const link = data ? '/dashboard' : 'https://www.cargoflip.com/';
  return (
    <NavbarContainer>
      <div className={'w-full px-10'}>
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="shrink-0 text-lg text-primary-navy-400">
              <a className="font-bold hover:text-gray-200" href={link}>
                <AppLogo />
              </a>
            </div>
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
