import Container from '@components/common/container';
import Badge from '@components/dataDisplay/badge';
import Button from '@components/general/button';
import HybridLayout from 'layout/HybridLayout';

const Page404 = () => {
  return (
    <Container>
      <div className="bg-white h-[calc(100vh-7rem)] flex items-center px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="w-[36rem] mx-auto max-h-full">
          <main className="flex flex-col justify-center items-center gap-y-2">
            <div>
              <Badge variant="danger">
                <div className="text-semantic-danger-500">Error code: 404</div>
              </Badge>
            </div>
            <div>
              <p className="text-[40px] text-primary-grey-500 text-center">
                We couldn’t find that page
              </p>
            </div>
            <div>
              <p className="text-base text-primary-navy-400 text-center">
                We're sorry, but the page you're looking for can't be found. It may have been
                removed or never existed in the first place
              </p>
            </div>
            <div className="mt-2 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Button variant="primary" link="/dashboard">
                Go back
              </Button>
              <Button variant="secondary" href="mailto:contact@cargoflip.com">
                Contact support
              </Button>
            </div>
          </main>
        </div>
      </div>
    </Container>
  );
};

Page404.WithoutAuth = true;
Page404.PageLayoutContainer = HybridLayout;

export default Page404;
