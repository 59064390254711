import Container from '@components/common/container';
import Navbar from '@components/navigation/navbar';

const SimpleLayout = ({ children }) => {
  return (
    <>
      <Container>
        <Navbar />
        {children}
      </Container>
    </>
  );
};

export default SimpleLayout;
